#cookiesBanner {
    z-index: 1;
    padding: 1.5rem;
    position: fixed;
    width: calc(100% - 2rem);
    max-width: 450px;
    left: 1rem;
    bottom: 1rem;
    color: black;
    background-color: white;
    border-radius: 20px;
    border: 1px solid #a7a7a7;
    p {
        text-align: justify;
    }
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        filter: grayscale(1);
        filter: opacity(0.03) grayscale(1);
    }
    button {
        cursor: pointer;
        background-color: white;
        border: 1px solid #ff6552;
        color: #ff6552;
        border-radius: 5px;
        -webkit-transition: .4s;
        transition: .4s;
        &:hover{
            color: white;
            background-color: #ff6552;
        }
    }
    .small {
        font-size: smaller;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 42px;
        height: 24px;
        margin-left: 0.5rem;
        /* Hide default HTML checkbox */
        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }
      
    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        &:before{
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }
        &.round {
            border-radius: 24px;
            &:before {
                border-radius: 50%;
            }
        }
    } 
    input:disabled{
        cursor:  not-allowed;
    }
    input:checked:not([disabled]) + .slider {
        background-color: #ff6552;
    }
    
    input:focus:not([disabled]) + .slider {
        box-shadow: 0 0 1px #ff6552;
    }
      
    input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
    }
}
#cookiesIcon {
    z-index: 1;
    cursor: pointer;
    position: fixed;
    left: 1rem;
    bottom: 1rem;
}